@media (orientation: portrait) {

}


.l-body{
    background: no-repeat url('../images/mainbg.png');
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: -81px;
}
.start-btn{
    z-index: 2;
    transition: 0.5s;
    background: #FCD535;
    color: #0e0e11;
    width: 160px;
    height: 60px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.1rem;
    margin-top: 72px;
}
.start-btn:hover{
    border: solid 2px #FCD535;
    background: rgba(38, 38, 38, 0.5);
    color: #FCD535;
}
.l-title{
    font-size: 5rem;
    color: white;
    font-weight: 900;
}
.l-block1{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



.l-block2{
    width: 100%;
    padding-top: 110px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.l-block2__item{
    width: 380px;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border-radius: 25px;
    background: #1A1B23;
}
.l-block2__title{
    font-size: 2rem;
    font-weight: 800;
    color: white;
    padding: 16px 0;
}
.l-block2__subtitle{
    font-size: 1rem;
    color: #898CA9;
    line-height: 70%;
}
.l-block2__lg{
     margin-top: 16px;
     margin-bottom: 32px;
     font-size: 1.1rem;
     font-weight: 600;
     color: #FCD535;
 }



.l-block3__subtitle{
    font-size: 1rem;
    margin-bottom: 0;
    color: #898CA9;
    line-height: 150%;
}
.l-block3{
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 140px;
}
.l-block3__title{
    margin-bottom: 0;
    font-size: 2.7rem;
    font-weight: 800;
    color: white;
}
.mb16{
    margin-bottom: 16px;
}



.l-block4{
    display: flex;
    margin-top: 100px;
    margin-bottom: 300px;
    width: 80%;
    justify-content: space-around;
}
.shield-img{
    width: 400px;
}
.l-block4__item{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.aic{
    align-items: center;
}
.l-block4__title{
    font-size: 3.5rem;
    line-height: 0.7;
    font-weight: 800;
    color: white;
}
.l-block__subtitle{
    font-size: 0.8rem;
    color: #898CA9;
    line-height: 70%;
}
.mb32{
    margin-bottom: 2rem;
}
.mtsub{
    margin-top: 2.5rem;
}



.l-block6{
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -200px;
    margin-bottom: 100px;
}
.l-graph__container{
    min-width: 1080px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    background: #1A1B23;
    border-radius: 25px;
}
.l-graph__item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    width: 95%;

}
.l-graph__coin{
    font-weight: 700;
    color: white;
    width: 15%;
    text-align: left;
    font-size: 20px;
}
.l-graph__title{
    font-weight: 700;
    color: white;
    font-size: 20px;
    width: 20%;
    text-align: left;
}
.l-graph__percent{
    font-weight: 700;
    width: 10%;
    text-align: left;
    font-size: 20px;
}
.text-error{
    color: #f6465d;
}
.l-graph__link{
    font-weight: 700;
    color: white;
    font-size: 20px;
    transition: 0.5s;
}
.l-graph__link:hover{
    color: #efb90b;
}
.l-graph-arrow{
    transition: 0.5s;
}
.l-graph__link:hover .l-graph-arrow{
    stroke: #efb90b;
}
.l-graph-arrow2{
    transition: 5s;
}
.l-graph__link:hover .l-graph-arrow2{
    stroke: #efb90b;
}
.l-graph__abr{
    width: 10%;
    text-align: left;
    font-weight: 700;
    color: #ffc500;
    font-size: 20px;
}
.l-graph__item:not(:last-child){
border-bottom: 2px solid #2B2C3B;
}
.l-block5{
    display: flex;
    width: 80%;
    justify-content: space-around;
}