.deposit-popup-window{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(28, 28, 28, 0.49);
}
.center-do{
    display: flex;
    padding-top: 200px;
    padding-bottom: 200px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.deposit-popup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28%;
    height: 340px;
    border-radius: 15px;
    background: #080808;
}
.dp-pp-text2{
    font-weight: 600;
    font-size: 1.2rem;
}