.comps__bar{
    width: 420px;
    background: #121318;
    padding: 20px 40px;
    border-radius: 10px;
    margin-bottom: auto;

}
.comps__contact{
    height: calc(100vh - 82px);
    min-height: 400px;
}
.comps__body{
    padding: 15px 8.5%;
    min-height: calc(100vh - 82px);
    background: #000;
    display: flex;
    justify-content: space-between;
    align-items: start;


}
.comps__content{
    margin-left: 24px;
    border-radius: 10px;
    color: white;
    background: #121318;
    line-height: 20px;
    padding: 20px 40px;
}

.bar__item{
    padding: 15px 20px;
    display: flex;
    border-radius: 10px;
    align-items: center;
}

.bar__item:not(:last-child){
    margin-bottom: 10px;
}


.bar__item:hover{
    background:#080808;
    color: #ffc500;
}
.bar__item-active{
    background:#080808;
    color: #ffc500;
}
.bar__text{
    vertical-align: middle;
    margin-bottom: 0;
    padding-left: 20px;
}