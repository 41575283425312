.footer-m{
    background: none;
    display: flex;
    padding: 30px 20px;
    justify-content: space-between;
}
.footer-section{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.footer-link{
    color: #848E9C;
    padding-bottom: 15px;
    padding-top: 15px;
    transition: 0.5s;
}
.footer-link:hover{
    color: #ffc500;
}
.rights{
    padding-bottom: 15px;
    color: rgba(132, 142, 156, 0.7);
}