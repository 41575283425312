
.flex-center
  display: flex
  height: calc(100vh - 69px)
  flex-direction: column
  align-content: center
  justify-content: center
  align-items: center
  width: 100%

.flex-center-reg
  display: flex

  flex-direction: column
  align-content: center
  justify-content: center
  margin-bottom: 100px
  align-items: center
  width: 100%


.loginform
  width: 32%


.loginform__title
  color: white
  margin-bottom: 50px
  font-size: 2rem


.loginform__input
  background: #0e0e11 !important
  color: white !important
  width: 100% !important
  height: 52px !important
  border-radius: 8px !important
  border: none !important
  outline: none
  font-size: 1.2rem !important
  padding-left: 10px
  margin-bottom: 30px !important


.loginform__input:focus-visible
  outline: 1px solid #FCD535
.loginform__input:focus
  outline: 1px solid #FCD535
.loginform__input:focus-within
  outline: 1px solid #FCD535


.loginform__forgot-container
  width: 100%
  text-align: right
  margin-bottom: 40px


.loginform__forgot
  color: #FCD535
  font-size: 1rem
.loginform__forgot:hover
  color: rgba(252, 213, 53, 0.71)

.error
  margin-bottom: 5px
  margin-left: 10px
.loginform__button
  width: 100%
  height: 50px
  background: #FCD535
  border-radius: 10px
  margin-bottom: 40px
  color: black
.loginform__button:hover
  background: rgba(252, 213, 53, 0.71)
.loginform__button:disabled
  background: rgba(252, 213, 53, 0.54)
  cursor: not-allowed


.loginform__iydha-container
  width: 100%
  
  
.loginform__iydha
  text-align: center
  color: white


.loginform__link
  color: #FCD535
.loginform__link:hover
  color: rgba(252, 213, 53, 0.71)

.loginform__input-password
  background: #0e0e11 !important
  color: white !important
  width: 100% !important
  height: 52px !important
  border-radius: 8px !important
  border: none !important
  outline: none
  font-size: 1.2rem !important
  margin-bottom: 30px !important


.loginform__input-password:focus-visible
  .loginform__input-container
    border: 1px solid rgba(254, 119, 1, 1) !important
.loginform__input-password:focus
  .loginform__input-container
    border: 1px solid rgba(254, 119, 1, 1) !important
.loginform__input-password:focus-within
  .loginform__input-container
    border: 1px solid rgba(254, 119, 1, 1) !important

.loginform__input-container
  background: #0e0e11 !important
  color: white !important
  width: 100% !important
  height: 52px !important
  border-radius: 8px !important
  border: none
  outline: none
  font-size: 1.2rem !important
  padding-left: 10px
  margin-bottom: 30px !important
  display: flex
  justify-content: space-between
  padding-right: 10px

.visibilityIcon
  cursor: pointer
  color: white
  font-size: 1.5rem
  height: 100% !important
  display: flex
  align-items: center


